import React from 'react'
import { Button, Modal } from 'react-bootstrap';

const ModalEntregaCac = ({ toggleFunction, modalState }) => {
  const titulo = 'Entrega de Carta de Asignación de Cupo Periodo 2025'
  const tituloVentana = 'Comunicado Importante'

  const info = {
    main: 'En vista de los problemas presentados en la frontera del día de hoy 3 de enero de 2025, para la entrada de ciudadanos extranjeros que vienen a iniciar posgrado y debían retirar el día de <b>HOY</b> la <b>CARTA DE ASIGNACIÓN DE CUPO</b>, con la cual deben presentarse el lunes 06 de Enero de 2025, 7:00am en sus respectivos hospitales, les informo: ',
    items: [
      'El proceso de entrada al país nunca es un proceso rápido, más aún si se viaja por carretera, donde en la zona de la Guajira venezolana y colombiana ocurren innumerables trancones en la vía que impiden el acceso, por tanto se debió pensar en esto y viajar el día antes, ya que como he escrito el ingreso es realmente impredecible. ',
      'Es necesario destacar que cada estudiante es responsable de sus acciones y de la forma adecuada de hacer sus procesos; no hay ninguna justificación para que alguien viaje el mismo día que estaba citado para la entrega de un documento importante como el que se tenía que entregar hoy. ',
      'El día de mañana sábado 04 de enero se estará entregando las cartas de asignación de cupo, en el horario de la mañana, en el mismo sitio, la sede de la División de Estudios para Graduados de la Facultad de Medicina de la Universidad del Zulia. '
    ]
  }

  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title className='fw-bold'>{tituloVentana}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          <div className='info-group mb-1'>
            {/* <h3 className='modal-subtitle continuous spaced-sm lh-base'>COMUNICADO IMPORTANTE</h3> */}
            <h3 className='modal-subtitle continuous spaced-lg lh-base mt-2'>{titulo}</h3>
            <h4 className='modal-subtitle spaced-md'>ATENCIÓN</h4>
          </div>
          <div className='info-group mb-3'>
            <p className='info-p justify' dangerouslySetInnerHTML={{__html: info.main}}/>
          </div>
          <div className='info-group mb-4'>
            <ol className='list-justify p-list'>
            {
              info.items.map((item, index) => 
                <li className='list-item' key={index}>{item}</li>
              )
            }
            </ol>
          </div>
          <div className='info-group'>
            <div className='d-flex flex-column justify-content-center '>
              <span className='fw-semibold'>Dr. Freddy Pachano Arenas</span>
              <span className='fw-semibold'>Director</span>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalEntregaCac }