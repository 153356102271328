import React, { useState } from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { Link } from 'react-router-dom'
import { Carousel, CarouselItem } from 'react-bootstrap'
import car_horarioAtencion from '../assets/images/Carrusel/HorarioAtencionDEPG.png'
import car_horarioAtencion_mb from '../assets/images/Carrusel/Horario-Atencion-DEPG-Mobile.png'
import car_consultaEstatus from '../assets/images/Carrusel/Consulta-Estatus-Administrativo-Landscape.png'
import car_consultaEstatus_mb from '../assets/images/Carrusel/Consulta-Estatus-Administrativo.png'
import car_pec from '../assets/images/Carrusel/PEC-Oct-LS.png'
import car_pec_mb from '../assets/images/Carrusel/PEC-Oct-MB.png'
import car_ctaBancaria from '../assets/images/Carrusel/Banner-Cuenta-Bancaria.png'
import car_ctaBancaria_mb from '../assets/images/Carrusel/Banner-Cuenta-Bancaria_Mobile.png'
import car_actOfCupos from '../assets/images/Carrusel/ActOfertaCupos-LS.png'
import car_actOfCupos_mb from '../assets/images/Carrusel/ActOfertaCupos-MB.png'
import car_actoGrado from '../assets/images/Carrusel/Recepcion-Acto-Grado-LS.png'
import car_actoGrado_mb from '../assets/images/Carrusel/Recepcion-Acto-Grado-MB.png'
import car_concursoDoc from '../assets/images/Carrusel/ConcursoDoctorados-LS.png'
import car_concursoDoc_mb from '../assets/images/Carrusel/ConcursoDoctorados-MB.png'
import car_inscripcionGdc from '../assets/images/Carrusel/InscripcionGanadores-LS.png'
import car_inscripcionGdc_mb from '../assets/images/Carrusel/InscripcionGanadores-MB.png'
import car_reconsideraciones from '../assets/images/Carrusel/Reconsideraciones2024-LS.png'
import car_reconsideraciones_mb from '../assets/images/Carrusel/Reconsideraciones2024-MB.png'
import car_entregaCartaCupo2025 from '../assets/images/Carrusel/Comunicado-CartaAsigCupo-LS.png'
import car_entregaCartaCupo2025_mb from '../assets/images/Carrusel/Comunicado-CartaAsigCupo-MB.png'
import { ModalConcurso } from '../components/ModalsHome/ModalConcurso'
import { ModalConsultaEstatus } from '../components/ModalsHome/ModalConsultaEstatus'
import { ModalPec } from '../components/ModalsHome/ModalPec'
import { ModalCtaBancaria } from '../components/ModalsHome/ModalCtaBancaria'
import { ModalCtecExt } from '../components/ModalsHome/ModalCTE'
import { ModalActCupos } from '../components/ModalsHome/ModalActCupos'
import { ModalCde } from '../components/ModalsHome/ModalCde'
import { ModalReconsideraciones } from '../components/ModalsHome/ModalReconsideraciones'
import { ModalRJCE } from '../components/ModalsHome/ModalRJCE'
import { ModalEntregaCac } from '../components/ModalsHome/ModalEntregaCAC'

const sideMenuOptions = [
  {
    displayName: "Consejo Técnico",
    path: "/consejo-tecnico"
  },
  {
    displayName: "Dirección",
    path: "/direccion"
  },
  {
    displayName: "Secretaría Docente",
    path: "/secretaria-docente"
  },
  {
    displayName: "Control de Estudios",
    path: "/control-estudios"
  },
  {
    displayName: "Acreditación y Currículo",
    path: "/acreditacion-curriculo"
  },
  {
    displayName: "Programa de Educación Continua (PEC)",
    path: "/cursos-pec"
  },
  {
    displayName: "Administración",
    path: "/administracion"
  },
  {
    displayName: "Archivo",
    path: "/archivo"
  }
]

const info = {
  ig_link: "https://www.instagram.com/depgfmluz/",
  tlg_link: "https://t.me/depgfmluz",
  correo: "depgfmluz@gmail.com",
  direccion_fisica: "Facultad de Medicina, Calle 65 con Av. 19, Edificio Ciencia y Salud 2do Piso. ",
  iframeSource: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1960.390514781335!2d-71.631306821881!3d10.674097514573457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e89990003e99c79%3A0x776e51f91b7316c!2sDIVISI%C3%93N%20DE%20ESTUDIOS%20PARA%20GRADUADOS%20FACULTAD%20DE%20MEDICINA%20LUZ!5e0!3m2!1ses-419!2sve!4v1714709802880!5m2!1ses-419!2sve",
  linkMaps: "https://www.google.com/maps?ll=10.674298,-71.630906&z=16&t=m&hl=es-419&gl=VE&mapclient=embed&cid=537869129268801900"
}

function Home() {
  const [modals, setModals] = useState([
    {
      id: 0,
      name: 'Concurso',
      state: false
    },
    {
      id: 1,
      name: 'Consulta Estatus',
      state: false
    },
    {
      id: 2,
      name: 'XI Congreso Facultad de Medicina',
      state: false
    },
    {
      id: 3,
      name: 'Nuevo Cronograma de Actividades | PEC',
      state: false
    },
    {
      id: 4,
      name: 'Asignacion Cuenta Bancaria',
      state: false
    },
    {
      id: 5,
      name: 'Unused',
      state: false
    },
    {
      id: 6,
      name: "Actualización de Cupos Concurso 2024",
      state: false
    },
    {
      id: 7,
      name: "Reinicio de Actividades - Septiembre 2024",
      state: false
    },
    {
      id: 8,
      name: "Recepción Acto de Grado",
      state: false
    },
    {
      id: 9,
      name: "Reconsideraciones 2024",
      state: false
    },
    {
      id: 10,
      name: "Premio Dr. Enrique Molina - Año 2023",
      state: false
    },
    {
      id: 11,
      name: "Reconocimiento a Jefes de Residencia 2024 y Carta de Egresado Año 2024",
      state: false
    },
    {
      id: 12,
      name: "Entrega Carta de Asignacion de Cupo Periodo 2025",
      state: false
    }
  ]);

  const HandleShowModals = (idModal) => setModals(
    modals.map(modal => ({
      ...modal,
      state: idModal === modal.id ? true : false
    }))
  );
  const HandleCloseModals = (idModal) => setModals(
    modals.map(modal => ({
      ...modal,
      state: idModal === modal.id ? !modal.state : false
    }))
  );

  const contenidoCarrusel = [
    {
      index: 0,
      titulo: "",
      descripcion: "",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Más información",
      showModal: () => HandleShowModals(12),
      imagen: car_entregaCartaCupo2025,
      imagenMobile: car_entregaCartaCupo2025_mb,
      imgLayout: "adjust",
      imgAlt: "Entrega Carta de Asignación de Cupo Periodo 2025"
    },
    {
      index: 1,
      titulo: "",
      descripcion: "",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Más información",
      showModal: () => HandleShowModals(9),
      imagen: car_reconsideraciones,
      imagenMobile: car_reconsideraciones_mb,
      imgLayout: "adjust",
      imgAlt: "Reconsideraciones 2024"
    },
    {
      index: 2,
      titulo: "",
      descripcion: "",
      caption: true,
      action: "path",
      actionLayout: "col",
      actionName: "Más información",
      path: "/concurso/listado-ganadores",
      imagen: car_inscripcionGdc,
      imagenMobile: car_inscripcionGdc_mb,
      imgLayout: "adjust",
      imgAlt: "Listado de Ganadores Concurso 2024"
    },
    {
      index: 3,
      titulo: "",
      descripcion: "",
      caption: true,
      action: "path",
      actionLayout: "col",
      actionName: "Más información",
      path: "/concurso/doctorados-2024",
      imagen: car_concursoDoc,
      imagenMobile: car_concursoDoc_mb,
      imgLayout: "adjust",
      imgAlt: "Concurso Doctorados 2024"
    },
    {
      index: 4,
      titulo: "",
      descripcion: "",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Más información",
      showModal: () => HandleShowModals(7),
      imagen: car_actoGrado,
      imagenMobile: car_actoGrado_mb,
      imgLayout: "adjust",
      imgAlt: "Recepción Acto de Grado | Control de Estudios DEPG"
    },
    {
      index: 6,
      titulo: "",
      descripcion: "Actualización Oferta de Cupos Concurso 2024",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Más información",
      showModal: () => HandleShowModals(6),
      imagen: car_actOfCupos,
      imagenMobile: car_actOfCupos_mb,
      imgLayout: "adjust",
      imgAlt: "Actualización Oferta de Cupos | Concurso 2024"
    },
    {
      index: 5,
      titulo: "",
      descripcion: "Cursos Programa de Educación Continua",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Más información",
      showModal: () => HandleShowModals(3),
      imagen: car_pec,
      imagenMobile: car_pec_mb,
      imgLayout: "adjust",
      imgAlt: "Programa de Educación Continua"
    },
    {
      index: 7,
      titulo: "",
      descripcion: "Asignación de Nueva Cuenta Bancaria",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Más información",
      showModal: () => HandleShowModals(4),
      imagen: car_ctaBancaria,
      imagenMobile: car_ctaBancaria_mb,
      imgLayout: "adjust",
      imgAlt: "Asignación de Cuenta Bancaria"
    },
    {
      index: 8,
      titulo: "Horario de Atención",
      descripcion: "",
      caption: false,
      imagen: car_horarioAtencion,
      imagenMobile: car_horarioAtencion_mb,
      imgLayout: "std",
      imgAlt: "Inicio de Actividades - Postgrado de Medicina LUZ"
    },
    {
      index: 9,
      titulo: "",
      descripcion: "Consulta a través de nuestro correo electrónico: infopagosdepgfmluz@gmail.com",
      caption: true,
      action: "modal",
      actionLayout: "col",
      actionName: "Ver más",
      showModal: () => HandleShowModals(1),
      imagen: car_consultaEstatus,
      imagenMobile: car_consultaEstatus_mb,
      imgLayout: "std",
      imgAlt: "Consulta tu Estatus Administrativo"
    }
  ]
  // console.log(contenidoCarrusel.sort((a,b) => a.index - b.index));

  return (
    <>
      <Header />
      <NavigationBar activeLink={"Inicio"} />
      <main className="main main-home ">
        <section className="main-section d-flex container-xxl">
          <div className="side-menu menu-xl left-container card">
            <ul className="side-list">
              {sideMenuOptions.map((element) => (
                <li key={element.displayName}>
                  <Link to={element.path} className="side-link">
                    »  {element.displayName}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <Carousel
            interval={10000}
            className="carousel-container carrusel-noticias"
          >
            {contenidoCarrusel.sort((a,b) => a.index - b.index).map((articulo, index) => (
              <CarouselItem key={index} className={`carousel-item ${articulo.imagenMobile ? 'mobile' : 'std'}`}>
                <img
                  src={articulo.imagen}
                  alt={articulo.imgAlt}
                  className={`carousel-img default ${articulo.imgLayout}`}
                />
                {
                  articulo.imagenMobile && 
                  <img 
                    src={articulo.imagenMobile} 
                    alt={articulo.imgAlt}
                    className={`carousel-img mobile`} 
                  />
                }
                <Carousel.Caption className={`carousel-caption ${articulo.caption === false && 'd-none'}`}>
                  {
                    articulo.titulo &&
                    <h3 className="carousel-caption-title">{articulo.titulo}</h3>
                  }
                  <div className={`caption-group ${articulo.action ? `action action-${articulo?.actionLayout}` : 'std'}`}>
                    <span className="carousel-caption-description">
                      {articulo.descripcion}
                    </span>
                    {
                      articulo.action && (
                        articulo.action === 'path' ? 
                        <Link 
                          className='carousel-action btn btn-light link-button rounded-pill'
                          to={articulo.path}
                        >
                          {articulo.actionName}
                        </Link>
                        :
                        <button 
                          className='carousel-action modal-action btn btn-light link-button rounded-pill'
                          onClick={articulo.showModal}
                        >{articulo.actionName}
                        </button>
                      )
                    }
                  </div>
                </Carousel.Caption>
              </CarouselItem>
            ))}
          </Carousel>

          <div className="side-menu menu-xl right-container">
            <div className="top-card card">
              <div className="card-header">
                <span className="fw-semibold">Horario de atención</span>
              </div>
              <div className="card-body d-flex flex-column">
                <span>Lunes a Viernes</span>
                <span>8:30 AM a 3:30 PM</span>
              </div>
            </div>

            <div className="bottom-card card">
              <div className="card-header">
                <span className="fw-semibold">Redes Sociales</span>
              </div>
              <div className="card-body d-flex flex-column">
                <a
                  className="d-flex align-items-center"
                  href={info.ig_link}
                  target="blank"
                >
                  <i className="bi bi-instagram"></i>
                  <span>Instagram</span>
                </a>
                <a href={info.tlg_link} target="blank">
                  <i className="bi bi-telegram"></i>
                  <span>Telegram</span>
                </a>
              </div>
            </div>
          </div>

          <div className="center-container">
            <div className="side-menu menu-lg left-container card">
              <ul className="side-list">
                {sideMenuOptions.map((element) => (
                  <li key={element.displayName}>
                    <Link to={element.path} className="side-link">
                      »  {element.displayName}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="side-menu menu-lg right-container">
              <div className="top-card card">
                <div className="card-header">
                  <span className="fw-semibold">Horario de atención</span>
                </div>
                <div className="card-body d-flex flex-column">
                  <span>Lunes a Viernes</span>
                  <span>8:30 AM a 3:30 PM</span>
                </div>
              </div>

              <div className="bottom-card card">
                <div className="card-header">
                  <span className="fw-semibold">Redes Sociales</span>
                </div>
                <div className="card-body d-flex flex-column">
                  <a
                    className="d-flex align-items-center"
                    href={info.ig_link}
                    target="blank"
                  >
                    <i className="bi bi-instagram"></i>
                    <span>Instagram</span>
                  </a>
                  <a href={info.tlg_link} target="blank">
                    <i className="bi bi-telegram"></i>
                    <span>Telegram</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className='section-ubicacion std-section'>
          <h2 className='section-header' id='ubicacion'>Ubicación</h2>

          <article className='map-container'>
            <div className='left-container'>
              <p className='info-ubicacion'>{info.direccion_fisica}</p>
            </div>

            <div className='right-container d-flex flex-column align-items-center'>
              <iframe 
                className='map-ubicacion'
                src={info.iframeSource} 
                title='Mapa de ubicación Posgrado de Medicina LUZ'
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>
              
              <a href={info.linkMaps} className='btn-maps btn btn-primary link-button' target='blank'>Ver en Google Maps</a>
            </div>
          </article>
        </section>
      </main>

      <ModalActCupos modalState={modals[6].state} toggleFunction={() => HandleCloseModals(6)} dialogClassName='custom-lg' />
      <ModalConcurso modalState={modals[0].state} toggleFunction={() => HandleCloseModals(0)} dialogClassName='custom-lg' />
      <ModalConsultaEstatus modalState={modals[1].state} toggleFunction={() => HandleCloseModals(1)} dialogClassName='custom-lg' />
      <ModalPec modalState={modals[3].state} toggleFunction={() => HandleCloseModals(3)} className='custom-lg' />
      <ModalCtaBancaria modalState={modals[4].state} toggleFunction={() => HandleCloseModals(4)} className='custom-lg' />
      <ModalCtecExt modalState={modals[5].state} toggleFunction={() => HandleCloseModals(5)} className='custom-lg' />
      <ModalCde modalState={modals[7].state} toggleFunction={() => HandleCloseModals(7)} className='custom-lg' />
      <ModalReconsideraciones modalState={modals[9].state} toggleFunction={() => HandleCloseModals(9)} className='custom-lg'/>
      <ModalRJCE modalState={modals[11].state} toggleFunction={() => HandleCloseModals(11)} className='custom-lg' />
      <ModalEntregaCac modalState={modals[12].state} toggleFunction={() => HandleCloseModals(12)} className='custom-lg' />
      <Footer />
    </>
  );
}

export { Home }